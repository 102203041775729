/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import BlogPosts from 'components/BlogPosts'
import ParseContent from 'components/shared/ParseContent'
import TitleDefault from 'components/elements/TitleDefault'
import HeroDefault from 'components/elements/HeroDefault'
import ContentDefault from 'components/elements/ContentDefault'
import CustomLink from 'components/shared/CustomLink'
import Testimonials from 'components/Testimonials'
import GravityForm from 'components/GravityForm'

import ChevronLeft from 'img/chevron-left.svg'

const Container = styled.div`
  position: relative;
  z-index: 1;
`

const BlogTitle = styled(TitleDefault)`
  font-size: ${props => props.theme.font.size.xxl};
  color: ${props => props.theme.color.text.main};
`

const BlogContent = styled(ContentDefault)`
  position: relative;

  & h2 {
    font-family: ${props => props.theme.font.family.main};
    font-weight: ${props => props.theme.font.weight.sl};
    color: ${props => props.theme.color.contrast};
  }
`

const BackLink = styled(CustomLink)`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.secondary} !important;

  &:hover {
    text-decoration: underline;
  }
`

const LinkText = styled.p`
  margin-bottom: 0;
`

const MostRead = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.secondary};
`

const MostReadTitle = styled.h3`
  font-family: ${props => props.theme.font.family.secondaryAlt};
  font-size: ${props => props.theme.font.size.xxl};
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    post: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      wordpress_id
      content
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        testimonial {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          quote
        }
        form
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }

    mostReadPosts: allWordpressPost {
      edges {
        node {
         wpId: wordpress_id
        }
      }
    }
  }
`

const PostTemplate = ({
  data: {
    post: { wordpress_id, path, title, content, yoastMeta, acf: { banner: { image }, testimonial,form } },
    mostReadPosts,
  },
}) => {


  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={image.localFile.childImageSharp.fluid.src}
        article
      />
      <HeroDefault
        image={image}
      />
      <section className="mb-5">
        <Container className="container py-5">
          <div className="row mb-5">
            <div className="col-lg-7">
              <BackLink to="/meer/nieuws/" className="d-flex align-items-center">
                <img src={ChevronLeft} className="mr-3" width="20" alt="" />
                <LinkText>Terug naar het overzicht</LinkText>
              </BackLink>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7 pr-5">
              <BlogTitle h1 className="mb-5 ">{title}</BlogTitle>
              <BlogContent className="mb-5" content={content} />
              {form ? (
                <GravityForm id={form} />
              ) : (
                <p />
              )}
              <BackLink to="/meer/nieuws/" className="d-flex align-items-center mb-5 mb-lg-0">
                <img src={ChevronLeft} className="mr-3" width="20" alt="" />
                <LinkText>Terug naar het overzicht</LinkText>
              </BackLink>
            </div>
            <MostRead className="col-lg-5 px-5 py-4">
              <MostReadTitle className="mb-5">Meer nieuws</MostReadTitle>
              <BlogPosts vertical postIds={mostReadPosts.edges.slice(0,4).filter(({ node: { wpId } }) =>  wpId !== wordpress_id).map(({ node : { wpId } }) => wpId)} />
            </MostRead> 
          </div>
        </Container>
      </section>
      <section>
        <Testimonials id={[testimonial.quote]} image={testimonial.image.localFile.childImageSharp.fluid} />
      </section>
    </Layout>
  )
}

export default PostTemplate
